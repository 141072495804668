@font-face {
  font-family: "ANBold";
  src: url("../fonts/AvenirNext-Bold-01.ttf") format("truetype");
}

@font-face {
  font-family: "ANDemibold";
  src: url("../fonts/AvenirNext-DemiBold-03.ttf") format("truetype");
}

@font-face {
  font-family: "ANMedium";
  src: url("../fonts/AvenirNext-Medium-06.ttf") format("truetype");
}

@font-face {
  font-family: "ANRegular";
  src: url("../fonts/AvenirNext-Regular-08.ttf") format("truetype");
}

.users-page{
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  width: 90%;
  align-items: center;
  /* for Firefox */
  min-height: 0;flex-grow: 1;

  display: flex;
  flex-direction: column;

  /* for Firefox */
  min-height: 0;
}

.user-stats-search-input{
    width: 80%;
    min-height: 30px;
    align-self: center;
    place-content: center;
    font-family: ANMedium;
    font-size: 19px;
    line-height: 20px;
    margin-bottom: 10px;
}

.user-stats-search-input::placeholder {
  opacity: 0.4;
  font-size: 19px;
  line-height: 20px;
}

.users-stats-table-container{
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-top: 20px;
  border-radius: 13px;
  box-shadow: 0 3px 9px 0 rgba(0,0,0,0.07);
  background-color: white;
  flex-grow: 1;

  overflow: auto;

  /* for Firefox */
  min-height: 0;
}


.users-stats-table-header-item{

}
.users-stats-table-header-order-item{}
.users-stats-table-header-order-item:hover{
  cursor: pointer;
}
.users-stats-table-header-text {
  font-family: ANDemibold;
  font-size: 18px;
  color: lightslategrey;
}

.users-stats-table-item-text {
  font-family: ANRegular;
  font-size: 16px;
  color: lightslategrey;
  text-align: center;
}

.hidden-table-column-item {
  color: lightslategrey;
  padding: 10px;
  font-size: 25px;
}

.hidden-table-column-item:hover {
  cursor: pointer;
  opacity: 0.8;
}

.paging-container{
  min-height: 50px;
  width: 80%;
  margin-top: 20px;
  border-radius: 13px;
  box-shadow: 0 3px 9px 0 rgba(0,0,0,0.07);
  background-color: white;
}