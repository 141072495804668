@font-face {
  font-family: "ANBold";
  src: url("../fonts/AvenirNext-Bold-01.ttf") format("truetype");
}

@font-face {
  font-family: "ANDemibold";
  src: url("../fonts/AvenirNext-DemiBold-03.ttf") format("truetype");
}

@font-face {
  font-family: "ANMedium";
  src: url("../fonts/AvenirNext-Medium-06.ttf") format("truetype");
}

@font-face {
  font-family: "ANRegular";
  src: url("../fonts/AvenirNext-Regular-08.ttf") format("truetype");
}

background-login-div{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F3F3F3;
  text-align: center;
}

.login-div{
  width: 25vw;
  margin: 27vh auto;
  border-radius: 13px;
  box-shadow: 0 3px 9px 0 rgba(0,0,0,0.07);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-logo-div{
  padding-top: 66px;
  padding-bottom: 23px;
}

.login-logo-img{
  height: 64px;
  width: 210px;
}
.login-logo-text{
  font-family: ANDemibold;
  letter-spacing: 0.75px;
  font-size: 18px;
  color: lightslategray;
}
.login-input-div{
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.login-input{
  width: 17vw;
  height: 44px;
  font-family: ANMedium;
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 10px;
  padding-left: 10px;
}

.login-input::placeholder {
  opacity: 0.4;
  font-size: 17px;
  line-height: 20px;
}

.login-btn{
  width: 15vw;
  height: 44px;
  background-color: lightslategray;
  border: 1px solid #E3E3E3;
  border-radius: 3px;
  color: white;
  margin-bottom: 20px;
  font-size: 15px;
  font-family: ANMedium;
}
.login-btn:disabled{
  color: #d4eeff;
}

.login-btn:hover:enabled{
  opacity: 0.8;
  cursor: pointer;
}

.err-msg{
  font-size: 13px;
  padding-bottom: 5px;
  color: #f75130;
  width: 70%;
  font-family: ANRegular;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .login-div{
    width: 50vw;
    margin: 35vh auto;

  }
  .login-input{
    width: 36vw;
  }

  .login-btn{
    width: 36vw;
  }
  .login-logo-div{
    padding-top: 40px;
  }
}

@media screen and (max-width: 800px) {
  .login-div{
    width: 50vw;
    margin: 35vh auto;

  }
  .login-input{
    width: 36vw;
  }

  .login-btn{
    width: 36vw;
  }
  .login-logo-div{
    padding-top: 40px;
  }
}

@media screen and (max-width: 450px) {
  .login-div{
    width: 80vw;
    margin: 12vh auto;

  }
  .login-input{
    width: 60vw;
  }

  .login-btn{
    width: 60vw;
  }
  .login-logo-div{
    padding-top: 40px;
  }
}

@media screen and (max-height: 450px) {
  .login-div{
    margin: 4vh auto;
  }
}

@media screen and (max-height: 400px) {
  .login-div{
    width: 80vw;
    margin: 2vh auto;

  }
  .login-input{
    width: 60vw;
  }

  .login-input-div{
    padding-bottom: 8px;
  }

  .login-btn{
    width: 60vw;
  }
  .login-logo-div{
    padding-top: 20px;
  }
}