@font-face {
  font-family: "ANBold";
  src: url("../fonts/AvenirNext-Bold-01.ttf") format("truetype");
}

@font-face {
  font-family: "ANDemibold";
  src: url("../fonts/AvenirNext-DemiBold-03.ttf") format("truetype");
}

@font-face {
  font-family: "ANMedium";
  src: url("../fonts/AvenirNext-Medium-06.ttf") format("truetype");
}

@font-face {
  font-family: "ANRegular";
  src: url("../fonts/AvenirNext-Regular-08.ttf") format("truetype");
}

.apple-font {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

body{
  background-color: #eff2f5;
}
table, th, td {
  border: 2px solid #eff2f5;
  border-collapse: collapse;
}
th, td {
  padding: 5px;
  text-align: center;
}

tbody tr:nth-child(odd) {
  background-color: rgba(255, 51, 204, 0);
}

tbody tr:nth-child(even) {
  background-color: rgba(102, 125, 141, 0.16);
}

input{
  border: 1px solid #E3E3E3;
  border-radius: 3px;
}

input:focus{
  border: 2px solid lightslategray;
  outline:none
}
table{
  width: 100%;
}

@mixin transition{
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}

@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.app-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 95%;
}

.head-panel{
  min-height: 60px;
  display: flex;
  width: 90%;
  border-radius: 13px;
  box-shadow: 0 3px 9px 0 rgba(0,0,0,0.07);
  background-color: white;
}

.head-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ANDemibold;
  font-size: 18px;
  color: lightslategray;
  margin-left: 10px;
  margin-right: 10px;
  &-active {
    @extend .head-item;
    font-size: 20px;
    color: #475565;
  }
}
.head-item:hover{
  opacity: 0.7;
}

.logout-btn{
  font-family: ANBold;
  font-size: 20px;
  margin-left: auto;
}

.logout-btn:hover{
  cursor: pointer;
  opacity: 0.7;
}

.spinner-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.arrow {
  border: solid rgba(119, 136, 153, 0);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.arrow-active {
  border: solid lightslategrey;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.save-delete-td {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.btn{
  border-radius: 10px;
  box-shadow: 0 3px 9px 0 rgba(0,0,0,0.07);
  min-width: 70px;
  height: 28px;
  margin: 3px;
  color: white;
  font-size: 18px;
  font-family: ANDemibold;
  border: none;
}

.btn:hover{
  cursor: pointer;
  opacity: 0.8;
}

.btn:focus {
  outline: none;
}

.save-btn {
  background-color: #1c994c;
}

.delete-btn {
  background-color: #cf3617;
}

.add-btn {
  background-color: #4389cf;
}
.add-btn:disabled{
  background-color: #a2a6bb;
  color: #dedbdd;
}

.cancel-btn {
  background-color: #f1f1f3;
  color: lightslategrey;
  border: 1px solid lightslategrey;
}

.add-container-btn {
  background-color: #4389cf;
  color: white;
  font-size: 20px;
  font-family: ANBold;
  transition-duration: .2s;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
}

.add-container-btn:hover {
  cursor: pointer;
  color: #4389cf;
  background-color: white;
}

.add-container{
  display: flex;
  justify-content: space-around;
  padding-top: 5px;
  padding-bottom: 5px;
}

.hover{}
.hover:hover{
  cursor: pointer;
  color: #4389cf;
}

.attention-container{
  background-color: white;
  border-radius: 8.01966px;
  padding-left: 25px;
  padding-right: 25px;
  width: 484px;
}

.attention-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 49px;
}

.attention-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 44px;
  padding-bottom: 20px;
}

@mixin onelight-btn() {
  @include noselect;
  @include transition;
  @extend .apple-font;
  background: #00DADA;
  border-radius: 25px;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  &:hover {
    cursor: pointer;
    background: #00AEAE;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.onelight-attention-text{
  @include noselect;
  @extend .apple-font;
  font-weight: bold;
  letter-spacing: -0.15px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 27px;
  line-height: 32px;
}

.onelight-attention-description-text{
  @include noselect;
  @extend .apple-font;
  letter-spacing: -0.15px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
}

.onelight-btn-primary{
  @include onelight-btn;
}

.onelight-delete-btn{
  @include onelight-btn;
  @extend .apple-font;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  height: 31px;
  color: rgba(253, 253, 255, 0.91);
  background-color: #EB5757;
  text-align: center;
}

.onelight-delete-btn:hover{
  background-color: #e15656;
  cursor: pointer;
}

.modal {
  top: 0!important;
  left: 0!important;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.29);
  transition: 1.1s ease-out;
  transform: scale(1);
  visibility: visible;
  position: fixed;
  z-index: 1000;
}

.modal-content {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1001;
  max-height: 100vh;
  overflow: auto;
}

.onelight-second-delete-btn{
  @include onelight-btn;
  @include transition;
  padding-left: 0px;
  padding-right: 0px;
  background-color: rgba(255, 255, 255, 0);
  color: #FF3232;
  opacity: 0.6;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    background-color: rgba(255, 255, 255, 0);
  }
}

.onelight-second-btn{
  @include onelight-btn;
  padding-left: 0px;
  padding-right: 0px;
  background-color: rgba(255, 255, 255, 0);
  color: #828282;
  &:hover {
    cursor: pointer;
    color: #5f5f5f;
    background-color: rgba(255, 255, 255, 0);
  }
}